<template>
  <b-card no-body>
    

    <b-card-body>
      <vue-apex-charts
        type="line"
        height="400"
        :options="data.chartOptions"
        :series="data.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>

import VueApexCharts from 'vue-apexcharts'





export default {
  props: ["data"],
  components: {
    VueApexCharts,
    
  },
  
}
</script>

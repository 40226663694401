<template>
  <div>
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="values in errors_back" :key="values">
          <li v-for="value in values" :key="value">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
    <b-row>
      <b-col v-if="showTotalSale" cols="12">
        <b-card title="Total Sale">
          <div>
            <validation-provider
              #default="{ errors }"
              name="Year"
              rules="required"
            >
              <b-form-group
                label="Year"
                label-for="Year"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  class="text-capitalize"
                  id="Year"
                  v-model="totalSaleByYear"
                  :clearable="false"
                  :state="totalSaleByYear === null ? false : true"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="dateOptionByYear"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </div>
          <div>
            <apex-line-chart :data="totalSale" />
          </div>
        </b-card>
      </b-col>
      <b-col v-if="showRevenu" cols="12">
        <b-card title="Revenu">
          <div>
            <validation-provider
              #default="{ errors }"
              name="Year"
              rules="required"
            >
              <b-form-group
                label="Year"
                label-for="Year"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  class="text-capitalize"
                  id="Year"
                  v-model="revenuByYear"
                  :clearable="false"
                  :state="revenuByYear === null ? false : true"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="dateOptionByYear"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </div>
          <div>
            <apex-line-chart :data="revenu" />
          </div>
        </b-card>
      </b-col>
      <b-col v-if="showRevenu" cols="12">
        <b-card title="Payouts List">
          <div>
            <validation-provider
              #default="{ errors }"
              name="Year"
              rules="required"
            >
              <b-form-group
                label="Year"
                label-for="Year"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  class="text-capitalize"
                  id="Year"
                  v-model="payoutsListByYear"
                  :clearable="false"
                  :state="payoutsListByYear === null ? false : true"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="dateOptionByYear"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </div>
          <div>
            <b-card v-if="tableData" no-body class="card-company-table">
              <b-table
                :items="tableData"
                responsive
                show-empty
                :busy.sync="isBusy"
                :fields="fields"
                ref="admint"
                id="AdminsTable"
                class="mb-0"
                >\
                <template #cell(status)="data">
                  <b-badge variant="primary">
                    {{ data.value }}
                  </b-badge>
                </template>
              </b-table>
              <div>
                <b-pagination
                  @input="payoutsListgetData()"
                  aria-controls="AdminsTable"
                  v-model="currentPage"
                  :total-rows="rows"
                  align="center"
                  class="my-0"
                />
              </div>
            </b-card>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { required } from '@validations'
import VueApexCharts from 'vue-apexcharts'
import ApexLineChart from './Analytics/ApexLineChart.vue'

import { $themeColors } from '@themeConfig'
export default {
  components: {
    VueApexCharts,
    ApexLineChart,
  },
  computed: {
    rows() {
      return this.totalRows
    },
  },
  watch: {
    totalSaleByYear: function () {
      this.totalSaleGetData()
    },
    revenuByYear: function () {
      this.revenuGetData()
    },
    payoutsListByYear: function () {
      this.payoutsListgetData()
    },
  },
  data() {
    return {
      required,
      currentPage: 1,
      totalRows: 1,
      isBusy: false,
      errors_back: [],
      showDismissibleAlert: false,
      dateOptionByYear: [],
      showTotalSale: false,
      totalSaleByYear: null,
      totalSale: {
        series: [
          {
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          markers: {
            strokeWidth: 7,
            strokeOpacity: 1,
            strokeColors: [$themeColors.light],
            colors: [$themeColors.warning],
          },
          colors: [$themeColors.warning],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'straight',
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          // tooltip: {
          //     custom(data) {
          //         return `${'<div class="px-1 py-50"><span>'}${
          //                             data.series[data.seriesIndex][data.dataPointIndex]
          //                           }</span></div>`
          //     },
          // },
          xaxis: {
            categories: [],
          },
          yaxis: {
            // opposite: isRtl,
          },
        },
      },
      showRevenu: false,
      revenuByYear: null,
      revenu: {
        series: [
          {
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          markers: {
            strokeWidth: 7,
            strokeOpacity: 1,
            strokeColors: [$themeColors.light],
            colors: [$themeColors.warning],
          },
          colors: [$themeColors.warning],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'straight',
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          // tooltip: {
          //     custom(data) {
          //         return `${'<div class="px-1 py-50"><span>'}${
          //                             data.series[data.seriesIndex]
          //                           }%</span></div>`
          //     },
          // },
          xaxis: {
            categories: [],
          },
          yaxis: {
            // opposite: isRtl,
          },
        },
      },
      payoutsListByYear: null,
      fields: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'storename',
          label: 'STORENAME',
        },
        {
          key: 'price',
          label: 'PRICE',
        },
        {
          key: 'status',
          label: 'STATUS',
        },
        {
          key: 'type',
          label: 'TYPE',
        },
        {
          key: 'commission',
          label: 'COMISSION',
        },
        {
          key: 'order_id',
          label: 'ORDER ID',
        },
        {
          key: 'qun',
          label: 'QUN',
        },
      ],
      tableData: [],
    }
  },
  created() {
    this.dateDiff()
  },
  methods: {
    totalSaleGetData() {
      axios
        .post('reports/payouts-keyvalue/total-sale-range', {
          year: this.totalSaleByYear,
        })
        .then((result) => {
          const data = result.data.data
          let keys = Object.keys(data)
          let arrKeys = []
          for (let index in keys) {
            arrKeys.push(keys[index])
          }
          for (let index in keys) {
            this.totalSale.chartOptions.xaxis.categories.push(
              arrKeys[index].toString()
            )
            this.totalSale.series[0].data.push(data[arrKeys[index]].sum)
          }

          this.$nextTick(() => {
            this.showTotalSale = true
          })
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    revenuGetData() {
      axios
        .post('reports/payouts-keyvalue/revenu-range', {
          year: this.revenuByYear,
        })
        .then((result) => {
          const data = result.data.data

          let keys = Object.keys(data)
          let arrKeys = []
          for (let index in keys) {
            arrKeys.push(keys[index])
          }
          for (let index in keys) {
            this.revenu.chartOptions.xaxis.categories.push(arrKeys[index])
            this.revenu.series[0].data.push(data[arrKeys[index]].sum)
          }
          this.$nextTick(() => {
            this.showRevenu = true
          })
          this.showRevenu = true
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    payoutsListgetData() {
      this.showDismissibleAlert = false
      // request vendors index API
      const promise = axios.post(
        'reports/payouts-list/total-sale-range/' + this.currentPage,
        {
          year: this.payoutsListByYear,
        }
      )
      return promise
        .then((result) => {
          const data = result.data.data.data

          this.totalRows = result.data.data.meta.total
          this.tableData.length = 0
          for (let index in data) {
            this.tableData.push({
              id: data[index].id,
              price: data[index].price,
              status: data[index].status,
              storename: data[index].vendor.storename,
              type: data[index].type,
              commission: data[index].rate,
              order_id: data[index].order_id,
              qun: data[index].quantity,
            })
          }
          this.tableData = [
            ...new Map(this.tableData.map((item) => [item.id, item])).values(),
          ]

          this.$refs.admint.refresh()
          return this.tableData
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    dateDiff() {
      const today = new Date()
      const oldday = new Date('1 January 2020')
      var ynew = today.getFullYear()
      var mnew = today.getMonth()
      var dnew = today.getDate()
      var yold = oldday.getFullYear()
      var mold = oldday.getMonth()
      var dold = oldday.getDate()
      var diff = ynew - yold
      if (mold > mnew) diff--
      else {
        if (mold == mnew) {
          if (dold > dnew) diff--
        }
      }
      this.totalSaleByYear = new Date().getFullYear().toString()
      this.revenuByYear = new Date().getFullYear().toString()
      this.payoutsListByYear = new Date().getFullYear().toString()
      for (var i = 0; i <= diff; i++) {
        this.dateOptionByYear.push(
          (parseInt(this.totalSaleByYear) - i).toString()
        )
      }
      return diff
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
    
